import request from "@/utils/request";

// 添加字母
export function addLetter(data) {
  return request({
      url: '/admin/french/letterAddLetter',
      method: 'post',
      data
  })
}

// 修改字母
export function updateLetter(data) {
  return request({
      url: '/admin/french/letterUpdateLetter',
      method: 'post',
      data
  })
}

//获取字母列表
export function getLetterList(params) {
  return request({
      url: '/admin/french/letterList',
      method: 'get',
      params
  })
}

//获取字母详情
export function getLetterDetail(params) {
  return request({
      url: '/admin/french/letterDetail',
      method: 'get',
      params
  })
}

// 获取上传COS签名
export function getReadCosToken(params) {
  return request({
      url: '/admin/french/readCosToken',
      method: 'get',
      params
  })
}
